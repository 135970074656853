import { useRef, useState } from "react";
import { Link as RouterLink, useNavigate } from "react-router-dom";
// material
import {
  Menu,
  MenuItem,
  IconButton,
  ListItemIcon,
  ListItemText,
} from "@mui/material";
// component
import Iconify from "../../../../components/Iconify";
import DelPopup from "src/components/DelPopup";

import { useSnackbar } from "notistack";
import { delStudent } from "src/DAL/student";
import { _delete_brand } from "src/DAL/brand";
import { useAppContext } from "src/hooks";
import { privilegesTypeCheckAndShowOrNot } from "src/utils/FormatPrivileges";
import { _close_support_ticket, _delete_support_ticket, _delete_support_ticket_comment } from "src/DAL/support_tickets";
// ----------------------------------------------------------------------

export default function SupportTicketCommentMoreMenu({ row, students, onStudentsUpdate,type, fetchCommentsDetail }) {
    console.log("row ______row ____test",row)
  const [openDelete, setOpenDelete] = useState(false);
  const [openClose, setOpenClose] = useState(false);
  const navigate = useNavigate();
  const { _get_user_profile, _get_user_privilege_access } = useAppContext();
  let get_user_profile = _get_user_profile();
  let get_user_privilege_access = _get_user_privilege_access();

  const { enqueueSnackbar } = useSnackbar();
  const handleDeleteClick = () => {
    setIsOpen(false);
    setOpenDelete(true);
  };
  const handleCloseClick = () => {
    setIsOpen(false);
    setOpenClose(true);
  };

  const handleCloseOk = () => {
    handleCloseClickAPI(row);
    setOpenClose(false); // Call the onDelete function when the user confirms the delete action
  };

  const handleDeleteOk = () => {
    handleClick(row);
    setOpenDelete(false); // Call the onDelete function when the user confirms the delete action
  };
  
  async function handleClick(row) {
    //console.log(row);
    const result = await _delete_support_ticket_comment(row._id);
    if (result.code === 200) {
        fetchCommentsDetail();
        // navigate("/support-tickets");

    //   const updatedStudents = students.filter(
    //     (students) => students.id !== row._id
    //   );
    //   onStudentsUpdate(updatedStudents);
      enqueueSnackbar("Deleted Successfully!", { variant: "success" });
    } else {
      enqueueSnackbar(result.message, { variant: "error" });
    }
  }
  const ref = useRef(null);
  const [isOpen, setIsOpen] = useState(false);

  return (
    <>
      <IconButton ref={ref} onClick={() => setIsOpen(true)}>
        <Iconify icon="eva:more-vertical-fill" width={20} height={20} />
      </IconButton>

      <Menu
        open={isOpen}
        anchorEl={ref.current}
        onClose={() => setIsOpen(false)}
        PaperProps={{
          sx: { width: 200, maxWidth: "100%" },
        }}
        anchorOrigin={{ vertical: "top", horizontal: "right" }}
        transformOrigin={{ vertical: "top", horizontal: "right" }}
      >
  
        <MenuItem   sx={{ color: "text.secondary", "&:hover": { color: "#0071cf" } }} onClick={handleDeleteClick}>
          <ListItemIcon>
            <Iconify icon="eva:trash-2-outline" width={24} height={24} />
          </ListItemIcon>
          <ListItemText
            primary="Delete"
            primaryTypographyProps={{ variant: "body2" }}
          />
        </MenuItem>
        
      </Menu>
      <DelPopup
        open={openDelete}
        setOpen={setOpenDelete}
        title="Confirm Delete"
        message="Are you sure you want to delete?"
        onOk={handleDeleteOk}
      />
     
    </>
  );
}
