import PropTypes from "prop-types";
// material
import { Paper, Typography } from "@mui/material";

// ----------------------------------------------------------------------

DataNotFoundCustomMessage.propTypes = {
    Heading: PropTypes.string,
    SubHeading: PropTypes.string,
};

export default function DataNotFoundCustomMessage({ Heading, SubHeading, ...other}) {
  return (
    <Paper {...other}>
      <Typography gutterBottom align="center" variant="subtitle1">
      <strong> {Heading} </strong> 
      </Typography>
      <Typography variant="body2" align="center">
        {SubHeading}
      </Typography>
    </Paper>
  );
}
