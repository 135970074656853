import PropTypes from "prop-types";
import React from "react";
import { useState, useEffect, useRef } from "react";
import CloseIcon from "@mui/icons-material/Close";
import { Icon } from "@iconify/react";
import { Form, FormikProvider } from "formik";
import closeFill from "@iconify/icons-eva/close-fill";
import {
  Card,
  Table,
  Stack,
  Avatar,
  Button,
  TableRow,
  TableBody,
  TableCell,
  TextField,
  Container,
  Typography,
  TableContainer,
  TablePagination,
  Tooltip,
  Badge,
  Checkbox,
  IconButton,
  AppBar,
  Toolbar,
  Grid,
  Paper,
} from "@mui/material";

import Scrollbar from "./Scrollbar";
import Box from "@mui/material/Box";
import Modal from "@mui/material/Modal";
import Iconify from "../Iconify";

 

// ----------------------------------------------------------------------
const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});

export default function CustomInputPaperViewFullLength({
    Name,
    Label,
    IconSet,
    marginTopSet
}) {
  return (
    <>
     <Grid item xs={12} md={12}>
        <Paper elevation={5} sx={{ position: 'relative', padding: '16px',marginTop:marginTopSet?marginTopSet:null }}>
          <Box className="d-flex flex-row justify-content-start">
            <div>
              <Typography variant="body1">
                <Iconify icon={IconSet?IconSet:"material-symbols:person-outline"} width={24} height={24} />
              </Typography>
            </div>
            <div className="px-2">
              <Typography variant="body1">{Name}</Typography>
            </div>
          </Box>
          <Typography
            variant="body2"
            sx={{ position: 'absolute', top: -10, left: 0, backgroundColor: 'transparent', padding: '0px',paddingLeft: '20px' }}
          >
            {Label}
          </Typography>
        </Paper>
      </Grid>
    </>
  );
}
