import { format, formatDistanceToNow } from "date-fns";
import moment from "moment";
import { Iconify } from "src/components";
import { s3baseUrl } from "src/config/config";

// ----------------------------------------------------------------------

const getIcon = (name) => <Iconify icon={name} width={20} height={20} />;
const getImageIcon = (src, alt, width = 20, height = 20) => (
  <img src={src} alt={alt} width={width} height={height} />
);
export function timePreferencesObjTest(time_preferences_obj) {
  let obj = {};

  if (time_preferences_obj) {
    obj = {
      ...time_preferences_obj,
      type:
        time_preferences_obj.type && time_preferences_obj.type !== ""
          ? time_preferences_obj.type
          : "",

      start_time:
        time_preferences_obj.start_time &&
        time_preferences_obj.start_time !== ""
          ? time_preferences_obj.start_time
          : null,

      end_time:
        time_preferences_obj.end_time && time_preferences_obj.end_time !== ""
          ? time_preferences_obj.end_time
          : null,
    };
  } else {
    obj = {
      _id: "0",
      type: "",
      start_time: "00:00",
      end_time: "00:00",
    };
  }

  console.log("obj  __obj    __test  return obj", obj);
  return obj;
}



export function  getFileIcon(fileName, path) {
  const extension = fileName
  .substring(fileName.lastIndexOf(".") + 1)
  .toLowerCase();

switch (extension) {
  case "pdf":
    return getIcon("bxs:file-pdf"); // Assuming you have a function to get PDF icon
  case "png":
  case "jpg":
  case "jpeg":
    return getImageIcon(s3baseUrl + path); // Assuming you have a function to get image icon
  case "docx":
  case "doc":
    return getIcon("bxs:file-doc"); // Assuming you have a function to get MS Word icon

  case "rar":
    return getIcon("solar:winrar-bold"); // Assuming you have a function to get MS Word icon

  case "zip":
    return getIcon("formkit:zip"); // Assuming you have a function to get MS Word icon

  default:
    return getIcon("mdi:file"); // No matching icon found
}
}