import React from "react";
import { useNavigate } from "react-router-dom";

import { useState, useEffect } from "react";

import { useParams } from "react-router-dom";

import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";

import dayjs from "dayjs";
// material
import {
  Stack,
  TextField,
  MenuItem,
  Input,
  IconButton,
  Typography,
  Grid,
  Box,
} from "@mui/material";
import ClearIcon from "@mui/icons-material/Clear";

import { LoadingButton } from "@mui/lab";
import { useSnackbar } from "notistack";
// component
import { CircularLoader, Page } from "src/components";

import { Iconify } from "src/components";
import { s3baseUrl } from "src/config/config";
import { detailStudent, editStudent } from "src/DAL/student";

import { listActiveBatch } from "src/DAL/batch";
import {
  _detail_enquiry_student,
  _edit_enquiry_student,
} from "src/DAL/enquirystudent";
import { listCourse } from "src/DAL/course";
import { _detail_brand, _edit_brand } from "src/DAL/brand";
import { capitalizeFirstLetter } from "src/utils/formatText";
import CustomImageUploader from "src/components/GeneralComponents/CustomImageUploader";
import { _active_roles_for_user } from "src/DAL/role";
import { _detail_announcement, _edit_announcement } from "src/DAL/announcement";

const EditAnnouncement = () => {
  const navigate = useNavigate();
  const handleNav = () => {
    navigate(-1);
  };
  const [image, setImage] = useState();
  const [courses, setCourses] = useState([]);
  const [batches, setBatches] = useState([]);
  const [batchesId, setBatchesId] = useState([]);
  const [previewUrl, setPreviewUrl] = useState();
  const [isLoading, setIsLoading] = useState(true);
  const [show, setShow] = useState(false);
  const { enqueueSnackbar } = useSnackbar();
  const { id } = useParams();
  const [roles, setRoles] = useState([]);
  const [ticketImage, setTicketImage] = useState("");
  const [formInputs, setFormInputs] = useState({
    title: "",
    description: "",
    role_id: "",
    active_status: true,
    announcement_for: 1,
  });

  function handleFile(e) {
    // console.log(e.target.files);
    const file = e.target.files[0];
    // console.log(file);
    setImage(file);
    // console.log(image);
    setPreviewUrl(URL.createObjectURL(file));
    setShow(true);
  }
  function handleDeleteImage() {
    setImage("");
    // console.log(image);
    setShow(false);
  }

  const handleChange = (e) => {
    const { name, value } = e.target;
    // console.log(value, "kkkk");
    if (name === "batches") {
      // Find the selected batch object
      const selectedBatch = batches.find((batch) => batch.id === value);

      // Update the courses dropdown with the selected batch's courses array
      setCourses(selectedBatch.courses);
      setFormInputs((prevInputs) => ({
        ...prevInputs,
        [name]: value,
        courses: selectedBatch.courses.map((course) => course._id),
      }));
    } else {
      setFormInputs((prevInputs) => ({
        ...prevInputs,
        [name]: value,
      }));
    }
  };

  // const handleDateChange = (date) => {
  //   // const formattedDate = dayjs(date).format("YYYY-MM-DD");
  //   const formattedDate = dayjs(date).format("DD/MM/YYYY");
  //   setFormInputs((values) => ({ ...values, dob: formattedDate }));
  // };

  const handleDateChange = (date) => {
    const formattedDate = dayjs(date).format("YYYY-MM-DD");
    setFormInputs((values) => ({ ...values, dob: formattedDate }));
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    // setIsLoading(true);
    console.log("formInputs", formInputs);

    let postData = {
        title: formInputs.title,
        description: formInputs.description,
        attachment: ticketImage,
        status: formInputs.active_status,
        announcement_for: formInputs.announcement_for,
      //   role: formInputs.role_id,
      };
  
      if(formInputs.announcement_for===3){
          postData = {
              title: formInputs.title,
              description: formInputs.description,
              attachment: ticketImage,
              status: formInputs.active_status,
              announcement_for: formInputs.announcement_for,
              role: formInputs.role_id,
            };
      }

    console.log("postData", postData);

    setIsLoading(true);
    const result = await _edit_announcement(id, postData);
    console.log("result _edit_announcement", result);

    if (result.code === 200) {
      setIsLoading(false);
      enqueueSnackbar("Edited Successfully!", { variant: "success" });
      navigate("/announcements");
    } else {
      enqueueSnackbar(result.message, { variant: "error" });
      setIsLoading(false);
    }
  };

  async function fetchStudentDetail() {
    setIsLoading(true);
    const response = await _detail_announcement(id);
    console.log("_detail_announcement _detail_announcement", response);
    // enquiry_form

    if (response.code === 200) {
      setFormInputs({
        ...formInputs,
        ...response.announcement,
        title: capitalizeFirstLetter(response.announcement.title),
        description: capitalizeFirstLetter(response.announcement.description),
        active_status: response.announcement.status,
        role_id:
          response.announcement.announcement_for === 3
            ? response.announcement.role._id
            : "",
      });

      if (
        response.announcement.attachment &&
        response.announcement.attachment !== ""
      ) {
        setTicketImage(response.announcement.attachment);
      }

      setIsLoading(false);
    } else {
      enqueueSnackbar(response.message, { variant: "error" });
      setIsLoading(false);
    }
  }

  const fetchActiveRoles = async () => {
    setIsLoading(true);
    const response = await _active_roles_for_user();

    // list_brand

    console.log("response _active_roles_for_user", response);
    if (response.code === 200) {
      const roles = response.roles.map((role, index) => ({
        ...role,
        id: role._id,
        title: role.title,
        status: role.status,
        number: index + 1,
      }));

      // if (roles.length > 0) {
      //   setFormInputs((prevInputs) => ({
      //     ...prevInputs,
      //     role_id: roles[0].id,
      //   }));
      // }

      setRoles(roles);
      setIsLoading(false);
    } else {
      setIsLoading(false);
      enqueueSnackbar(response.message, { variant: "error" });
    }
  };

  useEffect(() => {
    fetchStudentDetail();
  }, []);

  useEffect(() => {
    fetchActiveRoles();
  }, []);

  if (isLoading) {
    return <CircularLoader />;
  }

  return (
    <Page title="Edit Announcement">
      <div className="container px-4 mb-5 container_in_all_pages">
        <Grid
          item
          xs={12}
          sm={10}
          md={8}
          lg={7}
          xl={7}
          container
          sx={{
            display: "flex",
            justifyContent: "space-between",
            alignItems: "center",
            marginBottom: "10px",
          }}
        >
          <IconButton onClick={handleNav} sx={{ flex: 0 }}>
            <Iconify icon="ep:back" />
          </IconButton>
        </Grid>
        <Grid
          item
          xs={12}
          sm={10}
          md={8}
          lg={7}
          xl={7}
          container
          sx={{
            display: "flex",
            justifyContent: "space-between",
            alignItems: "center",
            marginBottom: "10px",
          }}
        >
          <h4 className="title_heading_in_all_pages_top_left">
            {" "}
            Edit Announcement{" "}
          </h4>
        </Grid>

        <Grid item xs={12} sm={10} md={8} lg={7} xl={7}>
          <form
            autoComplete="off"
            onSubmit={handleSubmit}
            encType="multipart/form-data"
          >
            <Stack spacing={3}>
              <Stack direction={{ xs: "column", sm: "row" }} spacing={2}>
                <TextField
                  fullWidth
                  label="Title"
                  value={formInputs.title}
                  onChange={handleChange}
                  name="title"
                  required
                  // inputProps={{
                  //   pattern: "[A-Za-z]{3,}",
                  //   title:
                  //     "Please enter at least 3 letters with only alphabetical characters.",
                  // }}
                />
                <TextField
                  fullWidth
                  label="Status"
                  select
                  // required
                  value={formInputs.active_status}
                  onChange={handleChange}
                  name="active_status"
                >
                  <MenuItem value={true}>Active</MenuItem>
                  <MenuItem value={false}>Inactive</MenuItem>
                </TextField>
              </Stack>

              <Stack direction={{ xs: "column", sm: "row" }} spacing={2}>
                <TextField
                  fullWidth
                  label="Announcement For"
                  select
                  // required
                  value={formInputs.announcement_for}
                  onChange={handleChange}
                  name="announcement_for"
                >
                  <MenuItem value={1}>All</MenuItem>
                  <MenuItem value={2}>Managements</MenuItem>
                  <MenuItem value={3}>Role</MenuItem>
                  <MenuItem value={4}>Students</MenuItem>
                </TextField>

                {formInputs.announcement_for === 3 ? (
                  <TextField
                    fullWidth
                    label="Role"
                    select
                    value={formInputs.role_id}
                    onChange={handleChange}
                    name="role_id"
                    required
                    // SelectProps={{ multiple: true }}
                  >
                    {roles.map((role) => (
                      <MenuItem key={role.id} value={role.id}>
                        {capitalizeFirstLetter(role.title)}
                      </MenuItem>
                    ))}
                  </TextField>
                ) : (
                  ""
                )}
              </Stack>

              <TextField
                fullWidth
                label="Description"
                multiline // Enable multiline
                rows={3} // Set the number of rows to 3
                value={formInputs.description}
                onChange={handleChange}
                name="description"
                required
                // inputProps={{
                //   pattern: "[A-Za-z]{3,}",
                //   title:
                //     "Please enter at least 3 letters with only alphabetical characters.",
                // }}
              />

              <CustomImageUploader
                pathOfUploadedFile={ticketImage}
                setPathOfUploadedFile={setTicketImage}
                // imageFileHeading={"Image File"}
                imageFileHeading={"Upload Image ( 500*300 )"}
                imageFileSubHeading={"Image Size: 1MB or below :"}
                imageFileLimitSizeUpload={1}
                imageFileButtonText={"UPLOAD"}
              />

              <Stack alignItems="end">
                <LoadingButton
                  size="medium"
                  type="submit"
                  variant="contained"
                  loading={isLoading}
                  sx={{ width: "120px" }}
                >
                  Save
                </LoadingButton>
              </Stack>
            </Stack>
          </form>
        </Grid>
      </div>
    </Page>
  );
};

export default EditAnnouncement;
