import { invokeApi } from "src/utils";
export const delImage = async (data) => {
  const requestObj = {
    path: `api/app_api/delete_image`,
    method: "POST",
    headers: {
      "x-sh-auth": localStorage.getItem("token"),
    },
    postData: data,
  };
  return invokeApi(requestObj);
};
