import { invokeApi } from "src/utils";


export const _list_announcement = async (data) => {

    const requestObj = {
      path: `api/announcement/list_announcement?page=0&limit=99999`,
      method: "POST",
      headers: {
        // "Content-Type": "multipart/form-data",
        "x-sh-auth": localStorage.getItem("token"),
      },
      postData: data,
    };
    return invokeApi(requestObj);
  };

export const _list_user_announcement = async (data) => {

    const requestObj = {
      path: `api/announcement/list_user_announcement?page=0&limit=99999`,
      method: "POST",
      headers: {
        // "Content-Type": "multipart/form-data",
        "x-sh-auth": localStorage.getItem("token"),
      },
      postData: data,
    };
    return invokeApi(requestObj);
  };

  export const _add_announcement = async (data) => {

    const requestObj = {
      path: `api/announcement/add_announcement`,
      method: "POST",
      headers: {
        // "Content-Type": "multipart/form-data",
        "x-sh-auth": localStorage.getItem("token"),
      },
      postData: data,
    };
    return invokeApi(requestObj);
  };

  export const _delete_announcement = async (id) => {
    const requestObj = {
      path: `api/announcement/delete_announcement/` + id,
      method: "DELETE",
      headers: {
        "x-sh-auth": localStorage.getItem("token"),
      },
    };
  
    return invokeApi(requestObj);
  };

  export const _detail_announcement = async (id) => {
    const requestObj = {
      path: `api/announcement/detail_announcement/` + id,
      method: "GET",
      headers: {
        "x-sh-auth": localStorage.getItem("token"),
      },
    };
    return invokeApi(requestObj);
  };

  export const _edit_announcement = async (id, data) => {
    const requestObj = {
      path: `api/announcement/edit_announcement/` + id,
      method: "PUT",
      headers: {
        "x-sh-auth": localStorage.getItem("token"),
      },
      postData: data,
    };
    return invokeApi(requestObj);
  };