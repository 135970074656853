import { invokeApi } from "src/utils";

export const _list_role = async (data) => {

    const requestObj = {
      path: `api/role/list_role`,
      method: "POST",
      headers: {
        "Content-Type": "multipart/form-data",
  
        "x-sh-auth": localStorage.getItem("token"),
      },
      postData: data,
    };
    return invokeApi(requestObj);
  };


  export const _add_role= async (data) => {
    const requestObj = {
      path: `api/role/add_role`,
      method: "POST",
      headers: {
        // "Content-Type": "multipart/form-data",
        "x-sh-auth": localStorage.getItem("token"),
      },
      postData: data, 
    };
    return invokeApi(requestObj);
  };

export const _delete_role= async (id) => {
  const requestObj = {
    path: `api/role/delete_role/` + id,
    method: "DELETE",
    headers: {
      "x-sh-auth": localStorage.getItem("token"),
    },
  };

  return invokeApi(requestObj);
};

export const _detail_role = async (id) => {
  const requestObj = {
    path: `api/role/detail_role/` + id,
    method: "GET",
    headers: {
      "x-sh-auth": localStorage.getItem("token"),
    },
  };
  return invokeApi(requestObj);
};


export const _edit_role = async (id, data) => {
  const requestObj = {
    path: `api/role/edit_role/` + id,
    method: "PUT",
    headers: {
      "x-sh-auth": localStorage.getItem("token"),
    },
    postData: data,
  };
  return invokeApi(requestObj);
};

 
 
export const _get_user_roles = async () => {
  const requestObj = {
    path: `api/role/get_user_roles`,
    method: "GET",
    headers: {
      "x-sh-auth": localStorage.getItem("token"),
    },
  };

  return invokeApi(requestObj);
};
export const _active_roles_for_user = async () => {
  const requestObj = {
    path: `api/role/active_roles_for_user`,
    method: "GET",
    headers: {
      "x-sh-auth": localStorage.getItem("token"),
    },
  };

  return invokeApi(requestObj);
};
