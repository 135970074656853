import React from "react";
import { useNavigate } from "react-router-dom";
import { useState, useEffect } from "react";
// material
import {
  Stack,
  TextField,
  MenuItem,
  Typography,
  IconButton,
  Grid,
  Button,
  InputAdornment,
  Chip,
  Divider,
  Box,
  FormHelperText,
  Icon,
  CircularProgress,
  Zoom,
  Tooltip,
} from "@mui/material";
import { LoadingButton } from "@mui/lab";
import { useSnackbar } from "notistack";
// component
import { CircularLoader, Page } from "src/components";
import { Iconify } from "src/components";
import { addBatch } from "src/DAL/batch";

import { _course_instructors, listActiveCourse } from "src/DAL/course";

import { DemoContainer } from "@mui/x-date-pickers/internals/demo";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { MobileTimePicker } from "@mui/x-date-pickers/MobileTimePicker";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";
import dayjs from "dayjs";
import moment from "moment";
import ClearIcon from "@mui/icons-material/Clear";
import { listInstructor } from "src/DAL/instructor";

import { TimePicker } from "@mui/x-date-pickers/TimePicker";
import { renderTimeViewClock } from "@mui/x-date-pickers/timeViewRenderers";
import {
  capitalizeAllFirstLetters,
  capitalizeFirstLetter,
  truncateString,
} from "src/utils/formatText";
import { _list_active_vendor, _list_vendor } from "src/DAL/vendor";
import { listStudent } from "src/DAL/student";
import {
  _list_active_expense_category,
  _list_expense_category,
} from "src/DAL/expense_category";
import { _add_expense } from "src/DAL/expense";
import { useTheme } from "@mui/material/styles";

import {
  FormControl,
  FormControlLabel,
  Radio,
  RadioGroup,
} from "@mui/material";
import { green, red } from "@mui/material/colors";
import FileUploadIcon from "@mui/icons-material/FileUpload";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import { styled } from "@mui/material/styles";
import { addImage } from "src/DAL/uploadfiles";
import { delImage } from "src/DAL/delfiles";
import { s3baseUrl } from "src/config/config";
import { getFileIcon } from "src/utils/formatOther";
const Input = styled("input")({
  display: "none",
});

// ----------------------------------------------------------------------

export default function CustomImageUploaderMulti({
  pathOfUploadedFile,
  setPathOfUploadedFile,
  imageFileHeading,
  imageFileSubHeading,
  imageFileLimitSizeUpload,
  imageFileButtonText,
}) {
  const getIcon = (name) => <Iconify icon={name} width={60} height={60} />;
  const getImageIcon = (src, alt, width = 60, height = 60) => (
    <img src={src} alt={alt} width={width} height={height} />
  );

  {
    /* <a
href={s3baseUrl + filePath.path}
target="_blank"
rel="noopener noreferrer"
>  */
  }
 

  const [image, setImage] = useState();
  const [show, setShow] = useState(false);
  const [previewUrl, setPreviewUrl] = useState();
  const [previews, setPreviews] = useState();
  const [acceptableFile, setAcceptableFile] = useState("");
  const [message, setMessage] = useState("");
  const [color, setColor] = useState("success");
  const { enqueueSnackbar } = useSnackbar();
  const [isLoading, setIsLoading] = useState(false);

  const fileChangedHandler = (event) => {
    const selectedFiles = event.target.files;

    if (selectedFiles.length > 0) {
      //   const totalSize = Array.from(selectedFiles).reduce(
      //     (acc, file) => acc + file.size,
      //     0
      //   );

      Array.from(selectedFiles).forEach(async (file) => {
        if (file.size <= imageFileLimitSizeUpload * 1024 * 1024) {
          setImage(file);
          setPreviews(URL.createObjectURL(file));
          setPreviewUrl(URL.createObjectURL(file));
          setShow(true);
          setAcceptableFile("true");
          setMessage("Acceptable File");
          setColor("success");

          await uploadFileFromUser(file);
        } else {
          // If the total size exceeds the limit
          setAcceptableFile("false");
          setMessage(
            "Error: The total size of selected files exceeds the maximum allowed limit."
          );
          setColor("danger");

          enqueueSnackbar(
            "The total size of selected files exceeds the maximum allowed limit.",
            { variant: "error" }
          );
          //   setPathOfUploadedFile([]);
        }
      });
    }
  };

  const uploadFileFromUser = async (selected_file) => {
    const data = new FormData();
    data.append("file", selected_file);
    console.log(
      "selected_file selected_file ___addImage test selected_file",
      selected_file
    );
    console.log("1  ___addImage", 1);

    setIsLoading(true);
    const response = await addImage(data);

    console.log("response addImage ___addImage test", response);
    if (response.code === 200) {
      console.log(
        "selected_file inside selected_file ___addImage test selected_file",
        selected_file
      );

      let obj = {
        name: response.name,
        path: response.path,
      };
      console.log("obj obj  ___obj ___addImage test", obj);
      setIsLoading(false);
      //   setPathOfUploadedFile(response.path);
      //   setPathOfUploadedFile(prevPaths => [...prevPaths, ...response.path]);
      //   const fullPath = s3baseUrl + response.path; // Assuming s3baseUrl is your base URL
      setPathOfUploadedFile((prevPaths) => [...prevPaths, obj]);
    } else {
      //   setPathOfUploadedFile("");
      setIsLoading(false);
      console.log(`Error: ${response.status}`);
    }
  };

  const imageDelete = async (img) => {
    // console.log("hello");
    const data = new FormData();
    data.append("path", img);
    setIsLoading(true);
    const result = await delImage(data);
    if (result.code === 200) {
      console.log("Deleted");
      setImage("");
      setPreviewUrl("");
      setPreviews("");
      setShow(false);
      setAcceptableFile("");
      setPathOfUploadedFile([]);
      setIsLoading(false);
    } else {
      // setIsLoadingButton(false);
      console.log(`Error: ${result.status}`);
      setImage("");
      setPreviewUrl("");
      setPreviews("");
      setShow(false);
      setAcceptableFile("");
      setPathOfUploadedFile([]);
      setIsLoading(false);
    }
  };

  function handleDeleteImage() {
    imageDelete(pathOfUploadedFile);
  }

  const handleDeleteFile = (indexToDelete) => {
    // Create a copy of the pathOfUploadedFile array
    const updatedFiles = [...pathOfUploadedFile];

    // Remove the file at the specified index
    updatedFiles.splice(indexToDelete, 1);

    // Update the state with the modified array
    setPathOfUploadedFile(updatedFiles);
  };

  useEffect(() => {
    if (
      pathOfUploadedFile &&
      pathOfUploadedFile !== null &&
      pathOfUploadedFile !== ""
    ) {
      setAcceptableFile("true");
      setShow(true);
      setPreviewUrl(s3baseUrl + pathOfUploadedFile);
    }
    console.log(
      "pathOfUploadedFile     _____pathOfUploadedFile",
      pathOfUploadedFile
    );
  }, [pathOfUploadedFile]);

  return (
    <>
      <div className="row">
        <div className="col-12 col-sm-5">
          <p
            className="assignment_sub_heading_in_add_edit"
            style={{ marginBottom: "3px" }}
          >
            {imageFileHeading}
          </p>
          <label htmlFor="contained-button-file">
            <Input
              //   accept="image/*"
              // accept=".rar,.zip,.pdf,.doc,.docx"
              accept="image/*,.rar,.zip,.pdf,.doc,.docx"
              //   accept=".csv"
              id="contained-button-file"
              //   multiple
              type="file"
              name="image"
              onChange={fileChangedHandler}
            />

            <Button
              id="contained-button-file"
              className="small-outlined-button"
              variant="outlined"
              color={
                acceptableFile === "true"
                  ? "success"
                  : acceptableFile === "false"
                  ? "error"
                  : "info"
              }
              startIcon={
                isLoading ? <CircularProgress size={20} /> : <FileUploadIcon />
              }
              component="span"
              disabled={isLoading} // Disable the button when loading
            >
              {isLoading ? "Loading..." : imageFileButtonText}
            </Button>
          </label>

          <FormHelperText className="pt-0">
            {imageFileSubHeading}
          </FormHelperText>
        </div>

        <div
          className={`col-12 col-sm-12 mt-2`}
          style={{ display: "flex", flexWrap: "wrap" }}
        >
          {pathOfUploadedFile.map((filePath, index) => (
          <Box
          key={index}
          position="relative"
          display="inline-block"
          border="1px solid #cccccc55"
          p={1}
          //   mb={2} // Add margin bottom for spacing between files
          style={{ marginRight: "10px" }} // Adjust spacing between files
        >
          <div
                                style={{
                                  display: "flex",
                                  alignItems: "center",
                                }}
                              >
                                ({index + 1}) &nbsp;
                                <Box
                                  style={{
                                    display: "flex",
                                    justifyContent: "center",
                                    marginRight: "5px", // Adjust spacing between icon and text
                                  }}
                                >
                                  <a
                                    href={s3baseUrl + filePath.path}
                                    target="_blank"
                                    rel="noopener noreferrer"
                                  >
                                    {getFileIcon(filePath.name, filePath.path)}
                                  </a>
                                </Box>
                                <Box>
                                  <a
                                    href={s3baseUrl + filePath.path}
                                    target="_blank"
                                    rel="noopener noreferrer"
                                  >
                                    <Tooltip
                                      TransitionComponent={Zoom}
                                      title={
                                        filePath.name.length > 15
                                          ? capitalizeFirstLetter(filePath.name)
                                          : ""
                                      }
                                      arrow
                                    >
                                      {capitalizeFirstLetter(
                                        truncateString(filePath.name, 15)
                                      )}
                                    </Tooltip>
                                  </a>{" "}
                                  &nbsp;&nbsp;
                                </Box>
                              </div>
                              
              {/* <Box style={{ display: "flex", justifyContent: "center" }}>
                <a
                  href={s3baseUrl + filePath.path}
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  {getFileIcon(filePath.name, filePath.path)}
                </a>
              </Box>

              <Box>
                ({index + 1}){" "}
                <a
                  href={s3baseUrl + filePath.path}
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  <Tooltip
                    TransitionComponent={Zoom}
                    title={
                      filePath.name.length > 13
                        ? capitalizeFirstLetter(filePath.name)
                        : ""
                    }
                    arrow
                  >
                    {capitalizeFirstLetter(truncateString(filePath.name, 13))}
                  </Tooltip>
                </a>{" "}
                &nbsp;&nbsp;
              </Box> */}
              <IconButton
                aria-label="delete"
                onClick={() => handleDeleteFile(index)} // Pass index to delete handler
                style={{ position: "absolute", top: -10, right: -10 }}
              >
                <ClearIcon />
              </IconButton>
            </Box>
          ))}
        </div>
      </div>
    </>
  );
}
