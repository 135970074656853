import { invokeApi } from "src/utils";



export const _addEnquiryStudent = async (data) => {
    // const formDataArray = Array.from(data.entries());
  
    // console.log(formDataArray);
    const requestObj = {
      path: `api/enquiry_form/list_enquiry_form`,
      method: "POST",
      headers: {
        "Content-Type": "multipart/form-data",
  
        "x-sh-auth": localStorage.getItem("token"),
      },
      postData: data,
    };
    return invokeApi(requestObj);
  };


  export const _add_enquiry_form = async (data) => {
    const requestObj = {
      path: `api/enquiry_form/add_enquiry_form`,
      method: "POST",
      headers: {
        // "Content-Type": "multipart/form-data",
        "x-sh-auth": localStorage.getItem("token"),
      },
      postData: data, 
    };
    return invokeApi(requestObj);
  };
export const _delEnquiryStudent = async (id) => {
  const requestObj = {
    path: `api/enquiry_form/delete_enquiry_form/` + id,
    method: "DELETE",
    headers: {
      "x-sh-auth": localStorage.getItem("token"),
    },
  };

  return invokeApi(requestObj);
};

export const _detail_enquiry_student = async (id) => {
  const requestObj = {
    path: `api/enquiry_form/detail_enquiry_form/` + id,
    method: "GET",
    headers: {
      "x-sh-auth": localStorage.getItem("token"),
    },
  };
  return invokeApi(requestObj);
};


export const _edit_enquiry_student = async (id, data) => {
  const requestObj = {
    path: `api/enquiry_form/edit_enquiry_form/` + id,
    method: "PUT",
    headers: {
      "x-sh-auth": localStorage.getItem("token"),
    },
    postData: data,
  };
  return invokeApi(requestObj);
};

export const _update_enquiry_status = async (id, data) => {
  const requestObj = {
    path: `api/enquiry_form/update_enquiry_status/` + id,
    method: "POST",
    headers: {
      "x-sh-auth": localStorage.getItem("token"),
    },
    postData: data,
  };
  return invokeApi(requestObj);
};


// export const _update_enquiry_status = async (id) => {
//   const requestObj = {
//     path: `api/enquiry_form/update_enquiry_status/` + id,
//     method: "GET",
//     headers: {
//       "x-sh-auth": localStorage.getItem("token"),
//     },
//   };
//   return invokeApi(requestObj);
// };

export const _filter_enquiry_student = async (data) => {

  const requestObj = {
    path: `api/enquiry_form/enquiry_filter`,
    method: "POST",
    headers: {
      // "Content-Type": "multipart/form-data",
      "x-sh-auth": localStorage.getItem("token"),
    },
    postData: data,
  };
  return invokeApi(requestObj);
};


// export const _update_enquiry_status= async (id,data) => {

//   const requestObj = {
//     path: `api/enquiry_form/update_enquiry_status/`+ id,
//     method: "POST",
//     headers: {
//       // "Content-Type": "multipart/form-data",
//       "x-sh-auth": localStorage.getItem("token"),
//     },
//     postData: data,
//   };
//   return invokeApi(requestObj);
// };

// export const listStudent = async () => {
//   const requestObj = {
//     path: `api/student/list_student`,
//     method: "GET",
//     headers: {
//       "x-sh-auth": localStorage.getItem("token"),
//     },
//   };

//   return invokeApi(requestObj);
// };
// export const delStudent = async (id) => {
//   const requestObj = {
//     path: `api/student/delete_student/` + id,
//     method: "DELETE",
//     headers: {
//       "x-sh-auth": localStorage.getItem("token"),
//     },
//   };

//   return invokeApi(requestObj);
// };
// export const addStudent = async (data) => {
//   const formDataArray = Array.from(data.entries());

//   console.log(formDataArray);
//   const requestObj = {
//     path: `api/student/add_student`,
//     method: "POST",
//     headers: {
//       "Content-Type": "multipart/form-data",

//       "x-sh-auth": localStorage.getItem("token"),
//     },
//     postData: data,
//   };
//   return invokeApi(requestObj);
// };
// export const detailStudent = async (id) => {
//   const requestObj = {
//     path: `api/student/detail_student/` + id,
//     method: "GET",
//     headers: {
//       "x-sh-auth": localStorage.getItem("token"),
//     },
//   };
//   return invokeApi(requestObj);
// };
// export const editStudent = async (id, data) => {
//   const requestObj = {
//     path: `api/student/edit_student/` + id,
//     method: "PUT",
//     headers: {
//       "x-sh-auth": localStorage.getItem("token"),
//     },
//     postData: data,
//   };
//   return invokeApi(requestObj);
// };
