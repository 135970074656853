import { invokeApi } from "src/utils";

export const _list_user_notifications = async () => {
  const requestObj = {
    path: `api/notification/list_user_notifications`,
    method: "GET",
    headers: {
      "x-sh-auth": localStorage.getItem("token"),
    },
  };

  return invokeApi(requestObj);
};

export const _update_notification_count = async () => {
  const requestObj = {
    path: `api/notification/update_notification_count`,
    method: "GET",
    headers: {
      "x-sh-auth": localStorage.getItem("token"),
    },
  };

  return invokeApi(requestObj);
};
export const _mark_all_as_read = async () => {
  const requestObj = {
    path: `api/notification/mark_all_as_read`,
    method: "GET",
    headers: {
      "x-sh-auth": localStorage.getItem("token"),
    },
  };

  return invokeApi(requestObj);
};

export const _read_notification = async (id) => {
  const requestObj = {
    path: `api/notification/read_notification/` + id,
    method: "GET",
    headers: {
      "x-sh-auth": localStorage.getItem("token"),
    },
  };
  return invokeApi(requestObj);
};